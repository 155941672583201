export type DispatchRequest = {
  vehicleId: string;
  stopName: string;
  latitude: number;
  longitude: number;
  taskType: string;
};

export type ClearDispatchRequest = {
  vehicleId: string;
  rideId: string;
};

export type Route = {
  name: string;
  stops: RouteStop[];
};

export type RouteStop = {
  location: ViaStyleLocation;
  anchor?: string;
  stop_point_id: string;
};

export type StopList = {
  stops: RouteStop[];
  stop_list_id: string;
};

export interface ViaStyleLocation {
  lat: string;
  lng: string; // Via uses 'lng' where we usually use 'lon'
}

export enum DispatchMode {
  demo,
  riderExperience
}

export enum TaskType {
  DROPOFF = 'DROPOFF',
  PICKUP = 'PICKUP'
}

export type Vehicle = {
  updatedAt: number;
  vehicleId: string;
  driverId: string;
  speed: number;
  latitude: number;
  longitude: number;
  heading: number;
  nextStop?: string;
  odsRideId?: string;
};

export type State = {
  [mayId: string]: Vehicle;
};

export type VehicleUpdateAction = {
  type: string;
  vehicles: Vehicle[];
};

export interface Viewport {
  latitude: number;
  longitude: number;
  zoom: number;
}

export type Platform = 'gem-v3' | 'gem-v2' | 'rx450h-v1' | 'sienna-v1';
