import axios, { AxiosResponse } from 'axios';
import { API_URL } from './constants';
import {
  ClearDispatchRequest,
  DispatchRequest,
  RouteStop,
  TaskType,
  Vehicle
} from './type';

export const getVehicles = async (token?: string): Promise<Vehicle[]> => {
  const res = await axios({
    method: 'GET',
    url: API_URL + '/vehicle/list',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    responseType: 'json'
  });
  return res.data;
};

export const dispatchVehicle = async (
  vehicle: Vehicle,
  stop: RouteStop,
  taskType: TaskType,
  token?: string
): Promise<AxiosResponse> => {
  const body: DispatchRequest = {
    vehicleId: vehicle.vehicleId,
    stopName: stop.stop_point_id,
    latitude: parseFloat(stop.location.lat),
    longitude: parseFloat(stop.location.lng),
    taskType
  };
  try {
    return await axios({
      method: 'POST',
      url: API_URL + '/vehicle/dispatch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      data: body
    });
  } catch (e: any) {
    return e.response;
  }
};

export const clearDispatchVehicle = async (
  vehicle: Vehicle,
  token?: string
): Promise<AxiosResponse> => {
  const body: ClearDispatchRequest = {
    vehicleId: vehicle.vehicleId,
    rideId: vehicle.odsRideId ?? ''
  };
  try {
    return await axios({
      method: 'DELETE',
      url: API_URL + '/vehicle/dispatch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      data: body
    });
  } catch (e: any) {
    return e.response;
  }
};
